.borderTopWhite{
    border-top:1px solid black;
}

.borderBottomGray{
    border-bottom: 1px solid black;
}

.borderTopGray{
    border-top: 1px solid black;
}

.scrollbar-thin::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
}

.filter_hide{
    max-height: 0;
	overflow: hidden;

	/* Set our transitions up. */
	-webkit-transition: max-height 0.5s;
	-moz-transition: max-height 0.5s;
	transition: max-height 0.5s;
}

.filter_show {
	/* On hover, set the max-height to something large. In this case there's an obvious limit. */
	max-height: 400px;
    -webkit-transition: max-height 0.5s;
	-moz-transition: max-height 0.5s;
	transition: max-height 0.5s;
    overflow: visible;
}
